import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

class CourseFeatures extends React.Component {
  render() {

    return (
      
        <Container className="grid" align="justify">
        <Row>
          <Col>
          <Card  bg="info" text="black">
            <Card.Header as="h5">FEATURES AND HIGHLIGHTS </Card.Header>
          </Card>
          </Col>
        </Row><br />
        <Row>
          <Col>
            <Card>
              <Card.Header className="icon major style1 fa-laptop">  24 /7 Online access</Card.Header>
              <Card.Body>
                <Card.Text>
                  You get access to the Learning Management System (LMS) throughout the day and after hours – 24/7. 
                  Apart from the class recordings all installation guides, class presentations, sample codes, project documents are available in the LMS. 
                </Card.Text>
              </Card.Body>
            </Card>
            </Col>
            <Col>
            <Card>
              <Card.Header className="icon major style1 fa-users">  Collaborative learning environment</Card.Header>
              <Card.Body>
                <Card.Text>
                  Collaborative learning not only enhances the learning process, but also allows you to develop 
                  your interpersonal and communication skills. 
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Card>
              <Card.Header className="icon major style1 fa-calendar">  Weekend/Weekday Class</Card.Header>
              <Card.Body>
                <Card.Text>
                Two 2 hour sessions per week. <br />
                1 Weekday Session and 1 Weekend Session
                </Card.Text>
              </Card.Body>
            </Card>
            </Col>
            <Col>
            <Card>
              <Card.Header className="icon major style1 fa-money ">  Flexible payment options</Card.Header>
              <Card.Body>
                <Card.Text>
                  We recognise that people from all backgrounds want to improve on their skills so we try to help wherever possible. 
                  All Our training courses are available to be purchased on a flexible payment plan. 
                </Card.Text>
              </Card.Body>
          </Card>
          </Col>
        </Row>
        <br />
       <Row>
          <Col>
            <Card>
              <Card.Header className="icon major style1 fa-clock-o">  Interview Preparation</Card.Header>
              <Card.Body>
                  <Card.Text>
                    We guide you through the entire process and help you reach your professional goals. 
                    We help you with your resume preparation, interview preparation and mock interviews 
                    <br />** Optional  Co-Op opportunity and consulting opportunity with Svatva 
                  </Card.Text>
                </Card.Body>
            </Card>
          </Col>
          <Col>
              <Card>
                <Card.Header className="icon major style1 fa-video-camera">  Recorded sessions</Card.Header>
                <Card.Body>
                  <Card.Text>
                    Recorded HD quality sessions, giving you the ability to watch as many times as you like.
                  </Card.Text>
                </Card.Body>
              </Card>
          </Col>
        </Row>
        </Container>
      
    )
  }
}
export default CourseFeatures
