import React from 'react';

import Layout from '../../components/Layout';
import CourseFeatures from '../../components/CourseFeatures';
import pic1 from '../../assets/images/courses/mobile-app-developer-training.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const MobileAppDevTrng = () => (
  <Layout>
    <div id="main" >
      <div className="inner">
        <article className="box"> 
        <h1>Mobile Application Developer Training</h1>
        <span className="image main">
          <img src={pic1} alt="" />
        </span>
        </article>
        <article className="box">
        
        
        <Container>
          <div className="inner">
          <p>
            Our comprehensive <b>Mobile Application Developer</b> Training Program makes you proficient 
            in skills to work with back-end and front-end web technologies.
            This course is designed to help you master the most in-demand and critical skills for becoming a FullStack developer. 
            Especially if you're going for a job interview or have a Project that needs your best performance.
          </p>
          </div>
          <article>
            <p>
              With a balanced mix of theoretical concepts and hands-on exercises, comibined with a capstone project that is as
              close to the real things as it gets, we promise to equip you with the necessary skills that will enable you to perform
              as a full stack developer.This ia an intensive 16 Week intensive  program followed by an opportunity for a 10 – week co-op program.
            </p><br />
          </article>
        </Container>
        <Container>
          <Row>
            <Col>What this course offers
              <ListGroup>
                    <ListGroup.Item><span className="icon fa-check-square-o">  A huge project that will teach you everything you need to know to get started as a full-stack developer. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  In-depth understanding of concepts of system design, OOP, Design Patterns and Distributed Systems </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Awesome Quality Content in the form of virtual sessions and study material. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Strcutured rigourous learning plan with practical sessions </span></ListGroup.Item> 
              </ListGroup>
            </Col>
          </Row><br />
          <Row>
            <Col>Who this course is for 
              <ListGroup>
                    <ListGroup.Item><span className="icon fa-check-square-o">  This course is for students fresh out of college as well as experienced developers. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Anyone who wants to expand their skillset or become a freelance developer. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Programmers who are not feeling confident about their existing  skills</span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Anyone who wants to learn real-world skills and build real-world applications. </span></ListGroup.Item> 
                    <ListGroup.Item><span className="icon fa-check-square-o">  Anyone who wants to make a career change. </span></ListGroup.Item> 
              </ListGroup>
            </Col>
          </Row>
        </Container><br />
        <CourseFeatures /><br />
        

        <Accordion defaultActiveKey="0">
          <Card bg="info" text="black">
            <Card.Header as="h5">COURSE OUTLINE</Card.Header>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <span className="icon major style1 h5 fa-plus-circle" >    1.	Basics</span>         
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>1.1	Software Development Lifecycles – Agile, Waterfall and Hybrid</ListGroup.Item>
                <ListGroup.Item>1.2	SDLC Phases – Importance</ListGroup.Item>
                <ListGroup.Item>1.3	Agile Principles
                  <ListGroup>
                    <ListGroup.Item>1.3.1	Project Life cycle in Agile</ListGroup.Item>
                    <ListGroup.Item>1.3.2	Characteristics of an AGILE Development Team</ListGroup.Item>
                    <ListGroup.Item>1.3.3	Key developer Skills for Agile Projects</ListGroup.Item>
                    <ListGroup.Item>1.3.4	Agile Techniques of Developers</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>1.4	Scrum Methodology 
                  <ListGroup>
                    <ListGroup.Item>1.4.1	Scrum Team</ListGroup.Item>
                    <ListGroup.Item>1.4.2	Scrum Techniques</ListGroup.Item>
                    <ListGroup.Item>1.4.3	Scrum Artifacts</ListGroup.Item>
                    <ListGroup.Item>1.4.4	Scrum Meetings</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>1.5	IT Project Overview  </ListGroup.Item>
                <ListGroup.Item>1.6	Team Structure - Responsibilities  </ListGroup.Item>
                <ListGroup.Item>1.7	Full Stack Developer Role – Salary, Career Path, Responsibilities
                  <ListGroup>
                    <ListGroup.Item>1.7.1	Frontend developer Roadmap</ListGroup.Item>
                    <ListGroup.Item>1.7.2	Backend developer Roadmap</ListGroup.Item>
                    <ListGroup.Item>1.7.3	DevOps engineer Roadmap</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <span className="icon major style1 h5 fa-plus-circle" >    2.	Distributed Systems – System Design</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>2.1	Introduction to Distributed Systems</ListGroup.Item>
                <ListGroup.Item>2.2	CAP Theorem</ListGroup.Item>
                <ListGroup.Item>1.3	Agile Principles
                  <ListGroup>
                    <ListGroup.Item>1.3.1	Project Life cycle in Agile</ListGroup.Item>
                    <ListGroup.Item>1.3.2	Characteristics of an AGILE Development Team</ListGroup.Item>
                    <ListGroup.Item>1.3.3	Key developer Skills for Agile Projects</ListGroup.Item>
                    <ListGroup.Item>1.3.4	Agile Techniques of Developers</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>1.4	Scrum Methodology 
                  <ListGroup>
                    <ListGroup.Item>1.4.1	Scrum Team</ListGroup.Item>
                    <ListGroup.Item>1.4.2	Scrum Techniques</ListGroup.Item>
                    <ListGroup.Item>1.4.3	Scrum Artifacts</ListGroup.Item>
                    <ListGroup.Item>1.4.4	Scrum Meetings</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>1.5	IT Project Overview  </ListGroup.Item>
                <ListGroup.Item>1.6	Team Structure - Responsibilities  </ListGroup.Item>
                <ListGroup.Item>1.7	Full Stack Developer Role – Salary, Career Path, Responsibilities
                  <ListGroup>
                    <ListGroup.Item>1.7.1	Frontend developer Roadmap</ListGroup.Item>
                    <ListGroup.Item>1.7.2	Backend developer Roadmap</ListGroup.Item>
                    <ListGroup.Item>1.7.3	DevOps engineer Roadmap</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <span className="icon major style1 h5 fa-plus-circle" >    3.	Application Design Concepts and Principles</span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>3.1	SOLID principles</ListGroup.Item>
                <ListGroup.Item>3.2	Multitiered applications - Java EE</ListGroup.Item>
                <ListGroup.Item>3.3	Five tier model for logical separation of concerns</ListGroup.Item>
                <ListGroup.Item>3.4	Design Patterns</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <span className="icon major style1 h5 fa-plus-circle" >    4.	Front-end </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>4.1	HTML</ListGroup.Item>
                <ListGroup.Item>4.2	Basics of CSS</ListGroup.Item>
                <ListGroup.Item>4.3	Basics of JavaScript
                  <ListGroup>
                    <ListGroup.Item>4.3.1 JavaScript Frameworks</ListGroup.Item>
                    <ListGroup.Item>4.3.2	NodeJS Introduction</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>4.4	Basics of Typescript
                  <ListGroup>
                    <ListGroup.Item>4.4.1	Angular</ListGroup.Item>
                    <ListGroup.Item>4.4.2	ReactJS</ListGroup.Item>
                    <ListGroup.Item>4.4.3	VueJS</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>4.5	Package Managers   </ListGroup.Item>
                <ListGroup.Item>4.6	CSS Preprocessors</ListGroup.Item>
                <ListGroup.Item>4.7	CSS Frameworks - Bootstrap </ListGroup.Item>
                <ListGroup.Item>4.8	Angular
                  <ListGroup>
                    <ListGroup.Item>4.8.1 Angular - Directives</ListGroup.Item>
                    <ListGroup.Item>4.8.2	Angular - Routes</ListGroup.Item>
                    <ListGroup.Item>4.8.3	Angular - Components</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>4.9	React and Other Frontend Technologies</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <span className="icon major style1 h5 fa-plus-circle" >    5.	Back-end </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>5.1	Types of Programming Languages</ListGroup.Item>
                <ListGroup.Item>5.2	Java Language - Concepts</ListGroup.Item>
                <ListGroup.Item>5.3	Dependency Injection – Inversion of Control
                  <ListGroup>
                    <ListGroup.Item>5.4	Important Concepts - The Spring Framework</ListGroup.Item>
                    <ListGroup.Item>5.4	Important Concepts - Spring Modules</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>5.5 Service Oriented Architecture
                  <ListGroup>
                    <ListGroup.Item>5.5.1	Web Services – RESTFul and SOAP</ListGroup.Item>
                    <ListGroup.Item>5.5.2	Scrum Techniques</ListGroup.Item>
                    <ListGroup.Item>5.5.3	Scrum Artifacts</ListGroup.Item>
                    <ListGroup.Item>5.5.4	Scrum Meetings</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>5.6	Authentication/Authorization – Spring Security, OAUTH2.0, SAML</ListGroup.Item>
                <ListGroup.Item>5.7	SQL – Introduction</ListGroup.Item>
                <ListGroup.Item>5.8	ORM Frameworks - Hibernate</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="5">
              <span className="icon major style1 h5 fa-plus-circle" >    6.	Testing </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>6.1	Test Driven Development Concepts</ListGroup.Item>
                <ListGroup.Item>6.2	Unit Testing using Junit</ListGroup.Item>
                <ListGroup.Item>6.3	Mocking Frameworks
                  <ListGroup>
                    <ListGroup.Item>6.3.1	Testing with Angular Mock Services</ListGroup.Item>
                    <ListGroup.Item>6.3.2	Mockito framework for unit tests in Java </ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>6.4	Integration Testing</ListGroup.Item>
                <ListGroup.Item>6.5	User Acceptance Testing</ListGroup.Item>
                <ListGroup.Item>6.6	Quality Gates and Change Control </ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              <span className="icon major style1 h5 fa-plus-circle" >    7.	Dev Ops, Continuous Delivery/Integration/Deployment </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>7.1	DevOps Concepts
                  <ListGroup>
                      <ListGroup.Item>7.1.1	CI Pipeline</ListGroup.Item>
                      <ListGroup.Item>7.1.2	CD Pipeline</ListGroup.Item>
                    </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>7.2	Managing Servers</ListGroup.Item>
                <ListGroup.Item>7.3	Infrastructures as Code
                  <ListGroup>
                    <ListGroup.Item>7.3.1	Containers</ListGroup.Item>
                    <ListGroup.Item>7.3.2	Docker – Introduction, Basics</ListGroup.Item>
                    <ListGroup.Item>7.3.3	Configuration Management</ListGroup.Item>
                    <ListGroup.Item>7.3.4	Ansible -Introduction</ListGroup.Item>
                    <ListGroup.Item>7.3.5	Container Orchestration</ListGroup.Item>
                    <ListGroup.Item>7.3.6	Docker Swarm</ListGroup.Item>
                    <ListGroup.Item>7.3.7	Kubernetes – Introduction, Basics</ListGroup.Item>
                    <ListGroup.Item>7.3.8	Infrastructure Provisioning (AWS)</ListGroup.Item>
                  </ListGroup>
                </ListGroup.Item>
                <ListGroup.Item>7.5	Dev-Ops – Best practices</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              <span className="icon major style1 h5 fa-plus-circle" >    8.	Tools </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>
              <ListGroup>
                <ListGroup.Item>8.1	UML Tools – MS Visio, Star UML</ListGroup.Item>
                <ListGroup.Item>8.2	BPMN Tools – MS Visio, Camunda, Activiti</ListGroup.Item>
                <ListGroup.Item>8.3	BA Tools – JIRA, Confluence, MS Project, SharePoint, Trello (Kanban)</ListGroup.Item>
                <ListGroup.Item>8.4	Software Tools – SQL Basics, SQL Tools, Technology stacks </ListGroup.Item>
                <ListGroup.Item>8.5	Software Tools – IDEs (Eclipse. STS. IntelliJ IDEA) </ListGroup.Item>
                <ListGroup.Item>8.6	Team Structure - Responsibilities  </ListGroup.Item>
                <ListGroup.Item>8.7	Introduction to BPMN models</ListGroup.Item>
              </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="8">
              <span className="icon major style1 h5 fa-plus-circle" >    9.	Full-stack Developer Interview Prep </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="8">
              <Card.Body>
                <ListGroup>
                  <ListGroup.Item>9.1	Architecture</ListGroup.Item>
                  <ListGroup.Item>9.2	Java</ListGroup.Item>
                  <ListGroup.Item>9.3	Angular</ListGroup.Item>
                  <ListGroup.Item>9.4	SQL </ListGroup.Item>
                  <ListGroup.Item>9.5	System Design </ListGroup.Item>
                  <ListGroup.Item>9.6	Scalability</ListGroup.Item>
                  <ListGroup.Item>9.7	DevOps</ListGroup.Item>
                  <ListGroup.Item>9.8	General and Behavioral Questions</ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        </article>
      </div>
    </div>
  </Layout>
);

export default MobileAppDevTrng;
